<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-row>
            <v-col>
                <v-row class="main-title-img-2" align="center" justify="center">
                    <v-col
                            class="text-center"
                            cols="12"
                    >
                        <h1 class="text-h4 font-weight-bold white--text">
                            객실
                        </h1>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-container class="my-8">
                    <v-row>
                        <v-col cols="12" md="12">
                            <h1 class="about-sub-title">객실목록</h1>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-row>
                                <v-col
                                        v-for="(item,n) in roomList"
                                        :key="n"
                                        class="d-flex child-flex"
                                        cols="12" sm="4">
                                    <v-card
                                            outlined
                                            class="roomListCard"
                                            @click="btnClick(0, item)">
                                        <v-img
                                                :src="item.roomThumb"
                                                :lazy-src="item.roomThumb"
                                                aspect-ratio="1.5"
                                                class="grey lighten-2"
                                        ></v-img>

                                        <v-card-title class="text-h6" style="font-weight: bold">
                                            {{item.roomNm}}
                                        </v-card-title>
                                        <v-card-subtitle>
                                            기준인원:{{item.stdNop}} / 최대인원:{{item.maxNop}} <br>
                                            {{item.roomForm}}
                                        </v-card-subtitle>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'RoomList',
        components: {},
        data: () => ({

            roomList: [],

        }),
        created: function () {
            // 맨위로
            window.scrollTo(0, 0);
            this.$store.commit("room/CLEAR_ROOM_INFO");
            this.getList({roomGubun: 'R'});

        },
        methods: {
            getList(val) {
                return this.$store.dispatch("room/getRoomList", val)
                    .then((resp) => {
                        setTimeout(() => {
                            this.roomList = resp.message;

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            btnClick(val, item) {

                switch (val) {
                    case 0 : {  // 상세정보
                        this.$store.commit("room/SAVE_ROOM_INFO", item);
                        this.$router.push({path: "/place/roomList/roomInfo"});
                        //this.$router.push({name: "RoomInfo", params: item});
                        break;
                    }
                    case 1 : {  // 예약하기기
                        this.$store.commit("room/SAVE_ROOM_INFO", item);

                        break;
                    }
                }

            }
        },
        mounted() {
            this.$nextTick(() => {

            })
        },
    }
</script>

